import React, { Fragment } from 'react'
import { graphql } from 'gatsby'
import Showdown from 'showdown'
import { MDBRow, MDBCol, MDBContainer, MDBAnimation} from 'mdbreact'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { GatsbyImage } from 'gatsby-plugin-image'
import Hero from '../components/heroSubpage'
import Link from 'gatsby-link'
import Cta from '../components/cta'
import Card from '../components/card'
import SolutionBulletsVertical from '../components/solutionBulletsVertical'
import SolutionBulletsHorizontal from '../components/solutionBulletsHorizontal'
import SolutionText from '../components/solutionText'
import SolutionFeatures from '../components/solutionFeatures'
import SolutionCards from '../components/solutionCards'
import SolutionTextImage from '../components/solutionTextImage'
import SolutionBulletsImage from '../components/solutionBulletsImage'
import SolutionTextImageBelow from '../components/solutionTextImageBelow'
import SectionYouTube from '../components/sectionYouTube'
import SolutionCardSet from '../components/solutionCardSet'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fat } from '@fortawesome/pro-thin-svg-icons'

library.add(fat)

const ProductTemplate = ({ data }) => {
    const post = data.markdownRemark
    const converter = new Showdown.Converter()   

  return (
    <Layout>
      <div id="sub-page">
        <Hero
          class="intro-65"
          image={post?.frontmatter?.image?.childImageSharp?.gatsbyImageData}
          foreimage={null}
          alt={post.frontmatter.alttext}
          title={post.frontmatter.title}
          subtitle={post.frontmatter.subtitle}
          ctatext={post.frontmatter.ctatext}
          ctaslug={post.frontmatter.ctaslug}           
          type="root"
        />
      </div>

      <section className="bg-white">
        <MDBContainer>
          <MDBRow>
            <MDBCol>
              <div
                className={`font-w-400 text-xs-extra-large divlink ${post.html ? 'text-opening-para' : 'text-opening-para-mb0'}`}
                dangerouslySetInnerHTML={{
                  __html: post.frontmatter.openingpara,
                }}
              />
              <div
                className="mt-3 richtext divlink"
                dangerouslySetInnerHTML={{ __html: post.html }}
              />
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section> 

    {post.frontmatter.section && 
    <>
      {post.frontmatter.section.map((sections, index) => (
        <Fragment key={index}>
          {sections.type === 'bullets vertical' && (
            <SolutionBulletsVertical data={sections} sectionClass={sections.class} />
          )}
          {sections.type === 'text' && (
            <SolutionText data={sections} sectionClass={sections.class} sectionCenter={sections.center} />
          )}
          {sections.type === 'features' && (
            <SolutionFeatures data={sections} sectionClass={sections.class} />
          )}
          {sections.type === 'cards' && (
            <SolutionCards data={sections} sectionClass={sections.class} sectionCenter={sections.center} />
          )}
          {sections.type === 'bullets horizontal' && (
            <SolutionBulletsHorizontal data={sections} sectionClass={sections.class} sectionCenter={sections.center} col={sections.featurecols ? sections.featurecols : "4"} />
          )}
          {sections.type === 'text with image below' && (
            <SolutionTextImageBelow data={sections} sectionClass={sections.class} />
          )}
          {sections.type === 'text and image' && (
            <SolutionTextImage data={sections} sectionClass={sections.class} placement={sections.placement} />
          )}
          {sections.type === 'bullets and image' && (
            <SolutionBulletsImage data={sections} sectionClass={sections.class} placement={sections.placement} />
          )}
          {sections.type === 'video' && (
            <SectionYouTube data={sections} sectionClass={sections.class} placement={sections.placement} />
          )}          
          {sections.type === 'card set' && (
            <SolutionCardSet data={sections} sectionClass={sections.class} height="8rem" />
          )}
        </Fragment>
      ))}
      </>
    }

      {post.frontmatter.helpfulresources && 
        <section className="bg-white-grey-gradient">
          <MDBAnimation reveal type="fadeIn" duration="1587ms">
            <MDBContainer>
              <h2 className="font-alt font-w-700 letter-spacing-1 title-xs-large title-large text-left pb-5">
                {post.frontmatter.helpfulresources.title}
                <span id="helpful-resources" className="anchor">
                        &nbsp;
                </span> 
              </h2>
              
              {post.frontmatter.helpfulresources.subtitle && (
               <p className="text-medium">
                 {post.frontmatter.helpfulresources.subtitle}
               </p>
              )}

              {post.frontmatter.helpfulresources.helpfulres && 
                <MDBRow className="pt-5">
                  {post.frontmatter.helpfulresources.helpfulres.map((helpfulres, index) => (
                    <Card
                      key={index}
                      collg="4"
                      colmd="6"
                      height="9rem"
                      title={helpfulres.title}
                      subtitle={helpfulres.subtitle}
                      description={helpfulres.description}
                      image={ helpfulres.image.childImageSharp.gatsbyImageData }
                      alttext={helpfulres.alttext}
                      placement={helpfulres.placement}
                      link={ helpfulres.link ? helpfulres.link : helpfulres.document.publicURL }
                      titleclass="title-small"
                      descriptionClass="text-card-small py-2"
                    />
                  ))}
                </MDBRow>
              }
            </MDBContainer>
          </MDBAnimation>
        </section>
      }

      {post.frontmatter.cta && 
        <Cta
          ctatitle={post.frontmatter.cta.title}
          ctadescription={post.frontmatter.cta.description}
          ctalink={post.frontmatter.cta.link}
          ctalinktext={post.frontmatter.cta.linktext}
        />
      }
    </Layout>
  )
}
export default ProductTemplate

export function Head({ data, location }) {
  const post = data.markdownRemark
  return (
    <SEO
      title={post.frontmatter.meta.title}
      description={post.frontmatter.meta.title}
      url={location.pathname} 
      image={post.frontmatter.image.childImageSharp?.gatsbyImageData?.images?.fallback?.src}          
      canonical={location.pathname}                  
    />
  )
}

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(
      fields: { slug: { eq: $slug } }
      frontmatter: { template: { eq: "products" } }
    ) {
      fields {
        slug
      }
      html
      frontmatter {
        meta {
          title
          description
        }
        title
        subtitle
        ctatext
        ctaslug         
        openingpara
        date(formatString: "MMMM DD, YYYY")
        alttext
        image {
          childImageSharp {
            gatsbyImageData(width: 1920, quality: 90)
          }
        }
        section {
          title
          subtitle
          intro
          description
          class
          type
          link
          linktext
          placement
          document {
            publicURL
          }
          alttext
          image {
            childImageSharp {
              gatsbyImageData(quality: 90)
            }
          }
          feature {
            title
            subtitle
            icon
            description
            link
            linktext
            colour
            alttext
            image {
              childImageSharp {
                gatsbyImageData(quality: 90)
              }
            }
          }
          featurecols
        }
        helpfulresources {
          title
          subtitle
          helpfulres {
            title
            subtitle
            description
            placement
            link
            alttext
            image {
              childImageSharp {
                gatsbyImageData(quality: 90)
              }
            }
            document {
              publicURL
            }
          }
        }
        cta {
          title
          description
          linktext
          link
        }
      }
    }
  } 
`